// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bs-404-js": () => import("./../../../src/pages/bs/404.js" /* webpackChunkName: "component---src-pages-bs-404-js" */),
  "component---src-pages-bs-hvala-vam-js": () => import("./../../../src/pages/bs/hvala-vam.js" /* webpackChunkName: "component---src-pages-bs-hvala-vam-js" */),
  "component---src-pages-bs-impresum-js": () => import("./../../../src/pages/bs/impresum.js" /* webpackChunkName: "component---src-pages-bs-impresum-js" */),
  "component---src-pages-bs-index-js": () => import("./../../../src/pages/bs/index.js" /* webpackChunkName: "component---src-pages-bs-index-js" */),
  "component---src-pages-bs-kontakt-js": () => import("./../../../src/pages/bs/kontakt.js" /* webpackChunkName: "component---src-pages-bs-kontakt-js" */),
  "component---src-pages-bs-konzalting-js": () => import("./../../../src/pages/bs/konzalting.js" /* webpackChunkName: "component---src-pages-bs-konzalting-js" */),
  "component---src-pages-bs-o-nama-js": () => import("./../../../src/pages/bs/o-nama.js" /* webpackChunkName: "component---src-pages-bs-o-nama-js" */),
  "component---src-pages-bs-reference-js": () => import("./../../../src/pages/bs/reference.js" /* webpackChunkName: "component---src-pages-bs-reference-js" */),
  "component---src-pages-bs-well-karijera-js": () => import("./../../../src/pages/bs/well-karijera.js" /* webpackChunkName: "component---src-pages-bs-well-karijera-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-impresum-js": () => import("./../../../src/pages/impresum.js" /* webpackChunkName: "component---src-pages-impresum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-well-careers-js": () => import("./../../../src/pages/well-careers.js" /* webpackChunkName: "component---src-pages-well-careers-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-bs-blog-js": () => import("./../../../src/templates/bs/blog.js" /* webpackChunkName: "component---src-templates-bs-blog-js" */),
  "component---src-templates-bs-blog-post-bs-js": () => import("./../../../src/templates/bs/blog-post-bs.js" /* webpackChunkName: "component---src-templates-bs-blog-post-bs-js" */),
  "component---src-templates-bs-digitalni-marketing-js": () => import("./../../../src/templates/bs/digitalni-marketing.js" /* webpackChunkName: "component---src-templates-bs-digitalni-marketing-js" */),
  "component---src-templates-bs-graficki-dizajn-js": () => import("./../../../src/templates/bs/graficki-dizajn.js" /* webpackChunkName: "component---src-templates-bs-graficki-dizajn-js" */),
  "component---src-templates-bs-online-trgovina-js": () => import("./../../../src/templates/bs/online-trgovina.js" /* webpackChunkName: "component---src-templates-bs-online-trgovina-js" */),
  "component---src-templates-bs-portfolio-js": () => import("./../../../src/templates/bs/portfolio.js" /* webpackChunkName: "component---src-templates-bs-portfolio-js" */),
  "component---src-templates-bs-portfolio-post-bs-js": () => import("./../../../src/templates/bs/portfolio-post-bs.js" /* webpackChunkName: "component---src-templates-bs-portfolio-post-bs-js" */),
  "component---src-templates-bs-video-oglasavanje-js": () => import("./../../../src/templates/bs/video-oglasavanje.js" /* webpackChunkName: "component---src-templates-bs-video-oglasavanje-js" */),
  "component---src-templates-bs-web-dizajn-js": () => import("./../../../src/templates/bs/web-dizajn.js" /* webpackChunkName: "component---src-templates-bs-web-dizajn-js" */),
  "component---src-templates-digital-marketing-js": () => import("./../../../src/templates/digital-marketing.js" /* webpackChunkName: "component---src-templates-digital-marketing-js" */),
  "component---src-templates-graphic-design-js": () => import("./../../../src/templates/graphic-design.js" /* webpackChunkName: "component---src-templates-graphic-design-js" */),
  "component---src-templates-online-store-js": () => import("./../../../src/templates/online-store.js" /* webpackChunkName: "component---src-templates-online-store-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-portfolio-post-js": () => import("./../../../src/templates/portfolio-post.js" /* webpackChunkName: "component---src-templates-portfolio-post-js" */),
  "component---src-templates-video-advertisment-js": () => import("./../../../src/templates/video-advertisment.js" /* webpackChunkName: "component---src-templates-video-advertisment-js" */),
  "component---src-templates-web-design-js": () => import("./../../../src/templates/web-design.js" /* webpackChunkName: "component---src-templates-web-design-js" */)
}

